























import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Component, Mixins } from 'vue-property-decorator';
import switchOff from './switch-off-smart-plug.gql';
import switchOn from './switch-on-smart-plug.gql';
import {
  SmartPlugSwitchOffSmartPlugMutation,
  SmartPlugSwitchOffSmartPlugMutationVariables,
} from './__generated__/SmartPlugSwitchOffSmartPlugMutation';
import {
  SmartPlugSwitchOnSmartPlugMutation,
  SmartPlugSwitchOnSmartPlugMutationVariables,
} from './__generated__/SmartPlugSwitchOnSmartPlugMutation';

@Component
export default class SmartPlugDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private readonly mainMetric = 'con';

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private async switchOff(): Promise<void> {
    try {
      await this.$apollo.mutate<SmartPlugSwitchOffSmartPlugMutation, SmartPlugSwitchOffSmartPlugMutationVariables>({
        mutation: switchOff,
        variables: { input: { spotId: this.spot.id } },
      });

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Gerät wurde ausgeschaltet', class: 'success' }],
      });
    } catch {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Ihr Gerät konnte nicht ausgeschaltet werden', class: 'error' }],
      });
    }
  }

  private async switchOn(): Promise<void> {
    try {
      await this.$apollo.mutate<SmartPlugSwitchOnSmartPlugMutation, SmartPlugSwitchOnSmartPlugMutationVariables>({
        mutation: switchOn,
        variables: { input: { spotId: this.spot.id } },
      });

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Gerät wurde eingeschaltet', class: 'success' }],
      });
    } catch {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Ihr Gerät konnte nicht eingeschaltet werden', class: 'error' }],
      });
    }
  }
}
